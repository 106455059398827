import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {PasswordField} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import noop from '@renofi/utilities/src/noop';

const Component = (props) => {
  const {label, name, placeholder} = props;
  const [focus, setFocus] = useState(false);

  const track = (type) => {
    sendEvent(type, {
      text: label || placeholder,
      name,
    });
  };

  const onBlur = (e) => {
    setFocus(false);
    track('FieldBlur');
    propOr(noop, 'onBlur', props)(e);
  };

  const onFocus = (e) => {
    setFocus(true);
    track('FieldFocus');
    propOr(noop, 'onFocus', props)(e);
  };

  return (
    <PasswordField focus={focus} {...props} onBlur={onBlur} onFocus={onFocus} />
  );
};

Component.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Component;
