import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';

const ENVIRONMENT = process.env.REACT_APP_ENV;
export const DEVELOPMENT = ENVIRONMENT === 'development';
export const PRODUCTION = ENVIRONMENT === 'production';

export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;
export const SEGMENT_URL = process.env.REACT_APP_SEGMENT_URL;
export const IS_NETLIFY_PREVIEW = isDeployPreviewUrl();
export const CONTRACTORS_FRONTEND_URL = IS_NETLIFY_PREVIEW
  ? window.location.origin.replace('login', 'contractors')
  : process.env.REACT_APP_CONTRACTORS_FRONTEND_URL;
export const SECURE_FRONTEND_URL = IS_NETLIFY_PREVIEW
  ? window.location.origin.replace('login', 'secure')
  : process.env.REACT_APP_SECURE_FRONTEND_URL;
export const BLUEPRINT_FRONTEND_URL = IS_NETLIFY_PREVIEW
  ? window.location.origin.replace('login', 'blueprint')
  : process.env.REACT_APP_BLUEPRINT_FRONTEND_URL;
export const LENDERS_FRONTEND_URL = IS_NETLIFY_PREVIEW
  ? window.location.origin.replace('login', 'lenders')
  : process.env.REACT_APP_LENDERS_FRONTEND_URL;
export const REVIEWERS_FRONTEND_URL = IS_NETLIFY_PREVIEW
  ? window.location.origin.replace('login', 'reviewers')
  : process.env.REACT_APP_REVIEWERS_FRONTEND_URL;

// temp code to compare env variables between this and monorepo
window._config = {
  NODE_ENV: process.env.NODE_ENV,
  ENVIRONMENT,
  DEVELOPMENT,
  PRODUCTION,
  SEGMENT_KEY,
  SEGMENT_URL,
  SECURE_FRONTEND_URL,
  BLUEPRINT_FRONTEND_URL,
  LENDERS_FRONTEND_URL,
  REVIEWERS_FRONTEND_URL,
};
