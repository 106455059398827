import {
  initAccessibe,
  initDataDog,
  initLogRocket,
  initSegment,
  initSentry,
} from '@renofi/analytics';

export default async ({config}) => {
  const {DEVELOPMENT, PRODUCTION} = config;

  if (!DEVELOPMENT) {
    initSegment();
  }

  if (PRODUCTION) {
    initLogRocket({sanitize: true});
    initSentry();
    initAccessibe();
  }

  initDataDog({envs: ['staging']});
};
