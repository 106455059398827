import {compose, withHandlers} from '@renofi/recompose';

import {GET_PROPERTY_DETAILS} from '../queries';

export default () =>
  compose(
    withHandlers({
      readPropertyDetails:
        ({client}) =>
        () => {
          const {propertyDetails} =
            client.readQuery({
              query: GET_PROPERTY_DETAILS,
            }) || {};
          return propertyDetails;
        },
    }),
    withHandlers({
      updatePropertyDetails:
        ({client, readPropertyDetails, propertyDetails: propertyDetailsProp}) =>
        (partial) => {
          const propertyDetails = readPropertyDetails() || propertyDetailsProp;
          const finalPropertyDetails = {
            ...propertyDetails,
            ...partial,
          };
          client.writeQuery({
            query: GET_PROPERTY_DETAILS,
            data: {
              propertyDetails: finalPropertyDetails,
            },
          });
          return finalPropertyDetails;
        },
    }),
  );
