import React from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {Button} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import noop from '@renofi/utilities/src/noop';

const Component = (props) => {
  const {trackingId, ...restOfProps} = props;

  const onClick = (event) => {
    const args = trackingId
      ? [trackingId]
      : [
          'ButtonClick',
          {
            text: event.target.textContent,
            name: props.name,
          },
        ];
    sendEvent(...args);
    propOr(noop, 'onClick', props)(event);
  };

  return <Button {...restOfProps} onClick={onClick} />;
};

Component.propTypes = {
  trackingId: PropTypes.string,
  name: PropTypes.string,
};

export default Component;
